import React, { Fragment, useContext, useEffect, useState } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { FilterParam, PaginationProps } from 'lib/pagination/types';
import type { PaginatedCollection, TrainingRequest } from 'models';

import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { approvalStatusLabelWithIcon } from 'helpers/models/trainingRequest';
import { pathToTrainingSessionDetails } from 'helpers/navigation';

import { newDataLoader } from 'lib/dataLoader';
import withPagination from 'lib/pagination/withPagination';
import { del, get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyState,
  FetchContainer,
  HamburgerMenu,
  Link,
  MenuItem,
  MenuList,
  SimpleSelectableTable,
  Text,
} from 'components';

import TrainingRequestManagementModal from 'scenes/components/TrainingRequestManagementModal';
import UserAvatar from 'scenes/components/UserAvatar';
import deleteRequestWithConfirmation from 'scenes/trainings/helpers/deleteRequestWithConfirmation';
import requestTableFilters from 'scenes/trainings/helpers/requestTableFilters';

import { RefreshContext } from '.';
import BulkActions from './BulkActions';
import NoRecordState from './NoRecordState';

type Props = {
  currentPeriodSlug: string;
  defaultFilter: FilterParam;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    trainingRequestCollection: PaginatedCollection<TrainingRequest>;
  };

const TrainingRequestList = ({
  currentPeriodSlug,
  page,
  countPerPage,
  queryParams,
  setPreviousPageParams,
  setNextPageParams,
  setQueryParams,
  trainingRequestCollection,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();
  const {
    setShouldRefreshStats,
    setShouldRefetchRequestList,
    shouldRefetchRequestList,
  } = useContext(RefreshContext);
  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(new Set());

  const { search, filter, userFilters } = queryParams;

  const [focusedTrainingRequest, setFocusedTrainingRequest] =
    useState<TrainingRequest | null>(null);

  useEffect(() => {
    if (shouldRefetchRequestList) {
      refetchData();
      setShouldRefetchRequestList(false);
    }
  }, [shouldRefetchRequestList, refetchData, setShouldRefetchRequestList]);

  const refetchDataAndStats = () => {
    setShouldRefetchRequestList(true);
    setShouldRefreshStats(true);
  };

  const deleteTrainingRequest = async (request: TrainingRequest) => {
    await dispatch(
      del(`training/periods/${currentPeriodSlug}/requests/${request.id}`)
    );

    const newIds = new Set(selectedRowIds);
    newIds.delete(request.id);
    setSelectedRowIds(newIds);

    refetchDataAndStats();
  };

  return (
    <Fragment>
      <Box>
        <DatatableWrapper
          collectionInfo={trainingRequestCollection}
          search={search}
          page={page}
          countPerPage={countPerPage}
          getPreviousPage={setPreviousPageParams}
          getNextPage={setNextPageParams}
          onQueryParamsChange={setQueryParams}
          isFetching={isFetching}
          hasError={hasError}
          totalCountRenderer={(count?: number | null) =>
            n__('%1 training request', '%1 training requests', count || 0)
          }
          renderNoRecord={() => <NoRecordState />}
          renderNoResult={() => (
            <EmptyState title={__('No training request matches your search')} />
          )}
          userFilters={userFilters}
          filter={filter}
          filters={requestTableFilters()}
          withSearch
          withUserMultiFilters
        >
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => (
              <SimpleSelectableTable
                selectedRowIds={selectedRowIds}
                setSelectedRowIds={setSelectedRowIds}
                rows={trainingRequestCollection?.items || []}
                keyFn={trainingRequest => trainingRequest.id}
                columns={[
                  {
                    header: __('Request summary'),
                    cell: trainingRequest => {
                      return (
                        <Link
                          hasInheritedColor
                          onClick={() =>
                            setFocusedTrainingRequest(trainingRequest)
                          }
                        >
                          {trainingRequest.title}
                        </Link>
                      );
                    },
                  },
                  {
                    header: __('Session name'),
                    cell: trainingRequest => {
                      if (trainingRequest.session)
                        return (
                          <Link
                            hasInheritedColor
                            to={pathToTrainingSessionDetails(
                              trainingRequest.session.id
                            )}
                          >
                            {trainingRequest.session?.name ||
                              __('Untitled session')}
                          </Link>
                        );

                      return (
                        <Text transformation="italic" color="light">
                          {__('Not linked to a session')}
                        </Text>
                      );
                    },
                  },
                  {
                    header: __('Employee'),
                    cell: request => (
                      <UserAvatar user={request.trainee} withJobTitle />
                    ),
                  },
                  {
                    header: __('Status'),
                    cell: request =>
                      approvalStatusLabelWithIcon(request.approvalStatus),
                  },
                  {
                    header: '',
                    cell: request => (
                      <HamburgerMenu>
                        <MenuList>
                          <MenuItem
                            onClick={() =>
                              deleteRequestWithConfirmation(
                                request,
                                deleteTrainingRequest
                              )
                            }
                            isDanger
                          >
                            {__('Delete')}
                          </MenuItem>
                        </MenuList>
                      </HamburgerMenu>
                    ),
                    isNarrow: true,
                  },
                ]}
                className="mb-2"
                rowClassName="test-training-request-row"
                renderBulkActions={(
                  selectedRequestIds,
                  resetSelectedRequestIds
                ) => (
                  <BulkActions
                    selectedRequestIds={selectedRequestIds}
                    resetSelectedRequestIds={resetSelectedRequestIds}
                    triggerDataRefetch={refetchDataAndStats}
                    periodSlug={currentPeriodSlug}
                  />
                )}
              />
            )}
          />
        </DatatableWrapper>
      </Box>

      {focusedTrainingRequest && (
        <TrainingRequestManagementModal
          currentPeriodSlug={currentPeriodSlug}
          trainingRequest={focusedTrainingRequest}
          onClose={() => {
            setFocusedTrainingRequest(null);
            refetchDataAndStats();
          }}
          linkedSessionName={
            focusedTrainingRequest.session
              ? focusedTrainingRequest.session.name || __('Untitled session')
              : null
          }
        />
      )}
    </Fragment>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      currentPeriodSlug,
    }: AfterPaginateProps) =>
      get(`training/periods/${currentPeriodSlug}/requests`, {
        page,
        countPerPage,
        search,
        userFilters,
        filter,
      }),
    hydrate: {
      trainingRequestCollection: {
        items: {
          creator: {},
          trainee: {
            manager: {},
          },
          trainingPeriod: {},
          session: {},
          trainingCourse: {
            organism: {},
          },
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      currentPeriodSlug,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        userFilters,
        filter,
        currentPeriodSlug,
      }),
  })
)(TrainingRequestList);
