import React, { useState } from 'react';

import type { User } from 'models';

import { __ } from 'helpers/i18n';
import queryString from 'helpers/queryString';

import parsePaginatedQueryParams from 'lib/pagination/parsePaginatedQueryParams';

import { Box, Button, Can, FeatureFlagged, Icon } from 'components';

import TrainingPeriodPicker, {
  FAKE_ALL_PERIODS_SLUG,
} from 'scenes/components/TrainingPeriodPicker';
import TrainingRequestModal from 'scenes/components/TrainingRequestModal';

import OldRequestTable from './OldRequestTable';
import RequestTable from './RequestTable';

type Props = {
  user: User;
};

const TrainingRequests = ({ user }: Props) => {
  const [requestCreationModalActive, setRequestCreationModalActive] =
    useState(false);
  const [shouldRefetchRequests, setShouldRefetchRequests] = useState(false);
  const { queryParams } = parsePaginatedQueryParams(
    queryString.parse(window.location.search)
  );
  const [selectedPeriodSlug, setSelectedPeriodSlug] = useState(
    queryParams?.periodSlug || FAKE_ALL_PERIODS_SLUG
  );

  return (
    <FeatureFlagged
      flag="revampTrainingProfileAndReview"
      alternativeChildren={
        <Box>
          <OldRequestTable userId={user.id} />
        </Box>
      }
    >
      <div className="flex justify-between mb-4">
        <div className="w-1/3">
          <TrainingPeriodPicker
            renderingStyle={'select'}
            currentPeriodSlug={selectedPeriodSlug}
            onChange={period => setSelectedPeriodSlug(period.slug)}
            allPeriodsOptionAvailable
          />
        </div>
        <Can perform={'create_training_request'} on={user}>
          <Button
            onClick={() => setRequestCreationModalActive(true)}
            color="primary"
          >
            <Icon name="add" className="mr-1" />
            {__('Make a request')}
          </Button>
        </Can>
      </div>
      <Box>
        <RequestTable
          user={user}
          shouldRefetchRequests={shouldRefetchRequests}
          setShouldRefetchRequests={setShouldRefetchRequests}
          additionalQueryParams={{
            sort: { requestedAt: 'desc' },
            periodSlug: selectedPeriodSlug,
          }}
        />
      </Box>
      {requestCreationModalActive && (
        <TrainingRequestModal
          isActive
          concernedUser={user}
          currentTrainingPeriodSlug={
            selectedPeriodSlug === FAKE_ALL_PERIODS_SLUG
              ? null
              : selectedPeriodSlug
          }
          onClose={() => setRequestCreationModalActive(false)}
          onAfterSubmit={() => setShouldRefetchRequests(true)}
        />
      )}
    </FeatureFlagged>
  );
};

export default TrainingRequests;
