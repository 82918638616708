import React, { Fragment } from 'react';
import { compose } from 'redux';

import type { User } from 'models';

import can from 'helpers/can';
import {
  useActiveUser,
  useCurrentOrganization,
  useOrganizationPlan,
} from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import {
  Match,
  pathToUserCareer,
  pathToUserImportedReviews,
  pathToUserInformation,
  pathToUserObjectives,
  pathToUserProfessionalBackground,
  pathToUserReviews,
  pathToUserSharedPeerFeedbacks,
  pathToUserTrainingRequests,
  pathToUserTrainingSessions,
} from 'helpers/navigation';
import { TrackView } from 'helpers/tracking';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Can,
  ContentContainer,
  FetchContainer,
  PageHeader,
  PageTitle,
  Redirect,
  Route,
  Switch,
} from 'components';

import PersonalObjectives from 'scenes/components/objectives/PersonalObjectives';
import SimbelTrainingRequestList from 'scenes/components/simbel/TrainingRequestList';

import ImportedReviews from './ImportedReviews';
import ProfessionalBackground from './ProfessionalBackground';
import Reviews from './Reviews';
import SharedPeerFeedbacks from './SharedPeerFeedbacks';
import TrainingRequests from './TrainingRequests';
import TrainingSessions from './TrainingSessions';
import UserAvatarWithImagePicker from './UserAvatarWithImagePicker';
import UserCareer from './UserCareer';
import UserInformation from './UserInformation';

type Props = {
  match: Match<{
    userReviewId: string;
  }>;
};

type AfterConnectProps = Props & {
  isFetching: boolean;
  hasError: boolean;
  user: User;
  activeUser: User;
  refetchData: () => Promise<any>;
};

const UserProfile = ({
  isFetching,
  hasError,
  user,
  match,
  refetchData,
}: AfterConnectProps) => {
  const activeUser = useActiveUser();
  const organizationPlan = useOrganizationPlan();
  const { isSimbelIntegrationActive, featureFlags } = useCurrentOrganization();
  const revampTrainingProfileAndReviewEnabled = featureFlags.includes(
    'revampTrainingProfileAndReview'
  );

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        let viewedAs: 'self' | 'manager' | 'admin' = 'self';

        if (user.id === activeUser.id) {
          viewedAs = 'self';
        } else if (user.managerId === activeUser.id) {
          viewedAs = 'manager';
        } else if (activeUser.isOrganizationAdmin) {
          viewedAs = 'admin';
        }

        const items = [
          {
            label: __('Profile'),
            testName: 'test-profile-tab',
            to: pathToUserInformation(user.id),
          },
        ];

        if (can({ perform: 'show_professional_background', on: user })) {
          items.push({
            label: __('Professional background'),
            testName: 'test-professional-background-tab',
            to: pathToUserProfessionalBackground(user.id),
          });
        }

        if (can({ perform: 'show_career', on: user })) {
          items.push({
            label: __('Career'),
            testName: 'test-career-tab',
            to: pathToUserCareer(user.id),
          });
        }

        if (can({ perform: 'show_reviews', on: user })) {
          items.push({
            label: __('Reviews'),
            testName: 'test-reviews-tab',
            to: pathToUserReviews(user.id),
          });
        }

        if (can({ perform: 'show_imported_reviews', on: user })) {
          items.push({
            label: __('Imported Reviews'),
            testName: 'test-imported-reviews-tab',
            to: pathToUserImportedReviews(user.id),
          });
        }

        if (can({ perform: 'manage_objectives', on: user })) {
          items.push({
            label: __('Objectives'),
            testName: 'test-objectives-tab',
            to: pathToUserObjectives(user.id),
          });
        }

        if (can({ perform: 'show_peer_feedbacks', on: user })) {
          items.push({
            label: __('Peer feedback shared'),
            testName: 'test-peer-feedback-tab',
            to: pathToUserSharedPeerFeedbacks(user.id),
          });
        }

        if (
          organizationPlan.trainingModuleEnabled &&
          can({ perform: 'show_training_requests_and_sessions', on: user })
        ) {
          if (
            !revampTrainingProfileAndReviewEnabled ||
            isSimbelIntegrationActive
          ) {
            items.push({
              label: __('Trainings'),
              testName: 'test-training-requests-tab',
              to: pathToUserTrainingRequests(user.id),
            });
          } else {
            items.push({
              label: __('Training requests'),
              testName: 'test-training-requests-tab',
              to: pathToUserTrainingRequests(user.id),
            });
            items.push({
              label: __('Trainings'),
              testName: 'test-training-sessions-tab',
              to: pathToUserTrainingSessions(user.id),
            });
          }
        }

        return (
          <Fragment>
            <PageTitle title={user.fullName} />
            <TrackView event="User profile viewed" properties={{ viewedAs }} />
            <PageHeader withBackButton tabItems={items}>
              <div className="w-full my-2">
                <UserAvatarWithImagePicker
                  user={user}
                  size="large"
                  withJobTitle
                />
              </div>
            </PageHeader>
            <ContentContainer>
              <Switch>
                <Route
                  path={`${match.path}/profile`}
                  render={() => (
                    <UserInformation user={user} refetchUser={refetchData} />
                  )}
                />

                <Route
                  path={`${match.path}/professional-background`}
                  render={() => (
                    <Can
                      perform="show_professional_background"
                      on={user}
                      alternativeChildren={
                        <Redirect to={pathToUserInformation(user.id)} />
                      }
                    >
                      <ProfessionalBackground user={user} />
                    </Can>
                  )}
                />

                <Route
                  path={`${match.path}/career`}
                  render={() => (
                    <Can
                      perform="show_career"
                      on={user}
                      alternativeChildren={
                        <Redirect to={pathToUserInformation(user.id)} />
                      }
                    >
                      <UserCareer user={user} />
                    </Can>
                  )}
                />

                <Route
                  path={`${match.path}/reviews`}
                  render={() => (
                    <Can
                      perform="show_reviews"
                      on={user}
                      alternativeChildren={
                        <Redirect to={pathToUserInformation(user.id)} />
                      }
                    >
                      <Reviews user={user} />
                    </Can>
                  )}
                />

                <Route
                  path={`${match.path}/objectives`}
                  render={() => (
                    <Can
                      perform="manage_objectives"
                      on={user}
                      alternativeChildren={
                        <Redirect to={pathToUserInformation(user.id)} />
                      }
                    >
                      <PersonalObjectives user={user} />
                    </Can>
                  )}
                />

                <Route
                  path={`${match.path}/peer-feedback/shared`}
                  render={() => (
                    <Can
                      perform="show_peer_feedbacks"
                      on={user}
                      alternativeChildren={
                        <Redirect to={pathToUserInformation(user.id)} />
                      }
                    >
                      <SharedPeerFeedbacks user={user} />
                    </Can>
                  )}
                />

                <Route
                  path={`${match.path}/trainings/sessions`}
                  render={() => (
                    <Can
                      perform="show_training_requests_and_sessions"
                      on={user}
                      alternativeChildren={
                        <Redirect to={pathToUserInformation(user.id)} />
                      }
                    >
                      {isSimbelIntegrationActive ? (
                        <Redirect to={pathToUserTrainingRequests(user.id)} />
                      ) : (
                        <TrainingSessions
                          userId={user.id}
                          userFullName={user.fullName}
                        />
                      )}
                    </Can>
                  )}
                />
                <Route
                  path={`${match.path}/trainings/requests`}
                  render={() => (
                    <Can
                      perform="show_training_requests_and_sessions"
                      on={user}
                      alternativeChildren={
                        <Redirect to={pathToUserInformation(user.id)} />
                      }
                    >
                      {isSimbelIntegrationActive ? (
                        <SimbelTrainingRequestList userId={user.id} />
                      ) : (
                        <TrainingRequests user={user} />
                      )}
                    </Can>
                  )}
                />
                {/* DEPRECATED in 22.522.1 */}
                <Route
                  path={`${match.path}/trainings`}
                  render={() => (
                    <Redirect to={pathToUserTrainingRequests(user.id)} />
                  )}
                />

                <Route
                  path={`${match.path}/imported_reviews`}
                  render={() => (
                    <Can
                      perform="show_imported_reviews"
                      on={user}
                      alternativeChildren={
                        <Redirect to={pathToUserInformation(user.id)} />
                      }
                    >
                      <ImportedReviews user={user} />
                    </Can>
                  )}
                />

                <Redirect to={`${match.path}/profile`} />
              </Switch>
            </ContentContainer>
          </Fragment>
        );
      }}
    />
  );
};

export default compose(
  newDataLoader({
    fetch: (ownProps: Props) => {
      const { id } = ownProps.match.params;
      invariant(id, 'User id must be provided');
      return get(`users/${id}`);
    },
    hydrate: {
      user: {
        manager: {},
        team: {},
        entity: {},
        lastUserSync: {},
        abilities: {},
        externalReviews: {},
        anonymizedBy: {},
        attributeOverrides: {},
      },
    },
    cacheKey: (ownProps: Props) => ownProps.match.params.id || '',
  })
)(UserProfile);
