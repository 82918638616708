import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/pagination/types';
import type { PaginatedCollection, TrainingSession } from 'models';

import compositeKey from 'helpers/compositeKey';
import { startToEndDateLabel } from 'helpers/date';
import { __, n__ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';
import { pathToTrainingSessionDetails } from 'helpers/navigation';

import { newDataLoader } from 'lib/dataLoader';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Can,
  DatatableWrapper,
  FetchContainer,
  Link,
  Number,
  SimpleTable,
  Text,
} from 'components';

import StatusTag from 'scenes/components/StatusTag';
import { FAKE_ALL_PERIODS_SLUG } from 'scenes/components/TrainingPeriodPicker';
import sessionTableFilters from 'scenes/trainings/helpers/sessionTableFilters';

import NoRecordState from './NoRecordState';
import NoResultState from './NoResultState';

type Props = {
  userId: string;
  userFullName: string;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    trainingRequestCollection: PaginatedCollection<TrainingSession>;
  };

const SessionTable = ({
  userFullName,
  page,
  countPerPage,
  queryParams: { search, filter, sort, periodSlug },
  setPreviousPageParams,
  setNextPageParams,
  setQueryParams,
  trainingRequestCollection,
  onSortChange,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <DatatableWrapper
      collectionInfo={trainingRequestCollection}
      search={search}
      page={page}
      countPerPage={countPerPage}
      getPreviousPage={setPreviousPageParams}
      getNextPage={setNextPageParams}
      onQueryParamsChange={setQueryParams}
      isFetching={isFetching}
      hasError={hasError}
      totalCountRenderer={(count?: number | null) =>
        n__('%1 training', '%1 trainings', count || 0)
      }
      renderNoRecord={() => (
        <NoRecordState
          userFullName={userFullName}
          onAllPeriods={periodSlug === FAKE_ALL_PERIODS_SLUG}
        />
      )}
      renderNoResult={() => (
        <NoResultState onAllPeriods={periodSlug === FAKE_ALL_PERIODS_SLUG} />
      )}
      filter={filter}
      filters={sessionTableFilters({ withAllStatuses: false })}
      withSearch
    >
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => (
          <SimpleTable
            rows={trainingRequestCollection?.items || []}
            keyFn={session => session.id}
            columns={[
              {
                header: __('Training name'),
                activeSort: sort && sort['name'],
                onSort: () => onSortChange && onSortChange('name'),
                cell: session => {
                  const name = session.name || __('Untitled session');

                  return (
                    <Can
                      perform={'update'}
                      on={session}
                      alternativeChildren={<Text>{name}</Text>}
                    >
                      <Link
                        to={pathToTrainingSessionDetails(session.id)}
                        openInNewTab
                        withOpenInNewTabIcon={false}
                      >
                        {name}
                      </Link>
                    </Can>
                  );
                },
              },
              {
                header: __('Status'),
                cell: session => {
                  const status = assertDefined(
                    session.status,
                    'status must be defined'
                  );
                  return (
                    <StatusTag
                      status={
                        ['in_preparation', 'prepared'].includes(status)
                          ? 'to_come'
                          : status
                      }
                    />
                  );
                },
              },
              {
                header: __('Session date'),
                cell: session => {
                  if (session.startDate === null && session.endDate === null) {
                    return (
                      <Text color="light" transformation="italic">
                        {__('No dates')}
                      </Text>
                    );
                  }

                  return startToEndDateLabel(
                    session.startDate,
                    session.endDate,
                    {
                      fromToLabelsAlwaysVisible: true,
                    }
                  );
                },
                activeSort: sort && sort['startDate'],
                onSort: () => onSortChange('startDate'),
              },
              {
                header: __('Number of hours'),
                cell: session =>
                  session.durationInHours
                    ? __('%1h', <Number value={session.durationInHours} />)
                    : '-',
              },
            ]}
            className="mb-2"
          />
        )}
      />
    </DatatableWrapper>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, sort, periodSlug },
      userId,
    }: AfterPaginateProps) =>
      get(`users/${userId}/training_sessions`, {
        page,
        countPerPage,
        search,
        filter,
        sort,
        periodSlug: periodSlug === FAKE_ALL_PERIODS_SLUG ? null : periodSlug,
      }),
    hydrate: {
      trainingRequestCollection: {
        items: {},
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, sort, periodSlug },
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        filter,
        sort,
        periodSlug,
      }),
  })
)(SessionTable);
